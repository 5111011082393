import React from 'react';
import ScheduleDataService from '../services/Schedule.services';
import moment from 'moment';
import { useUser } from '../contexts/user-context';
import { useLoading } from '../contexts/loading-context';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

export default function Holidays() {
    const { token } = useUser();
    const { setIsLoading } = useLoading();
    const year = new Date().getFullYear();

    const [holidays, setHolidays] = React.useState([]);

    React.useEffect(() => {
        handleGetHolidays();
    }, []);

    const handleGetHolidays = () => {
        setIsLoading(true);
        ScheduleDataService.getHolidays(token, year)
            .then(response => {
                setHolidays(response.data);
                setIsLoading(false);
            })
            .catch(e => {
                console.error(e);
                setIsLoading(false);
            });
    };

    const isHolidayPast = (date) => moment(date).isBefore(moment(), 'day');

    return (
        <div style={{ paddingTop: '1rem' }}>
            <List>
                <ListItem divider>
                    <Typography variant="h5" gutterBottom>
                        {`${year} Holidays`}
                    </Typography>
                </ListItem>
                {holidays.map((holiday) => (
                    <ListItem key={holiday.id} divider>
                        <ListItemText
                            primary={holiday.holiday_name.name}
                            secondary={`${moment(holiday.date).format('MMMM Do YYYY')}`}
                            primaryTypographyProps={{
                                style: isHolidayPast(holiday.date)
                                    ? { textDecoration: 'line-through', color: 'gray' }
                                    : {}
                            }}
                            secondaryTypographyProps={{
                                style: isHolidayPast(holiday.date)
                                    ? { color: 'gray' }
                                    : {}
                            }}
                        />
                    </ListItem>
                ))}
            </List>
            <Typography sx={{ m: 2 }} variant="body1" gutterBottom>
                To qualify for holiday pay, full-time employees and non-exempt employees must have worked or been paid for the scheduled workday before and after the holiday.
            </Typography>
        </div>
    );
}